import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["attachment", "fileControl", "fileInput"];
  static values = { required: Boolean };
  static classes = ["displayNone"];

  connect() {
    if (!this.hasAttachmentTarget) {
      this.fileControlTarget.classList.remove(this.displayNoneClass);
      this.fileInputTarget.required = this.requiredValue;
    }
  }

  remove() {
    const attachment = this.attachmentTarget;
    const fileControl = this.fileControlTarget;
    const fileInput = this.fileInputTarget;

    attachment.remove();
    fileControl.classList.remove(this.displayNoneClass);
    fileInput.required = this.requiredValue;
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// ---------------------
// Entry point for the build script in package.json

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "core-js/stable";
// Bootstrap is already imported by Niiwin, so leave this line commented out.
// import "bootstrap";
import "@fortawesome/fontawesome-free/js/all.js";

Rails.start();
ActiveStorage.start();

import "./channels";
import "./controllers";
import "./file-browser";

// Images
// Reference them with the image_pack_tag helper in views (e.g < %= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../assets/images", true);
const imagePath = (name) => images(name, true);

import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["subcategoryPicker"];

  reloadSubcategories() {
    if (event.target.selectedOptions[0].value === "") {
      this.subcategoryPickerTarget.innerHTML = "";
      return;
    }

    let params = new URLSearchParams();
    params.append("target", this.subcategoryPickerTarget.id);
    const categoryId = event.target.selectedOptions[0].value;

    get(
      `/i_app_data/i_event_categories/${categoryId}/subcategories?${params}`,
      {
        responseKind: "turbo-stream",
      }
    );
  }
}

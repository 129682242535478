import { Controller } from "@hotwired/stimulus";
import { escapeHTML } from "./html-utils";

/* A tagger is intended to look like a text input, but with the ability to
 * display and modify tags in a graphical way, mimmicking the ability to hold
 * "pills" of tags with remove buttons, that get created by typing text and
 * pressing space or comma, and that can be removed with a backspace or by
 * pressing the remove button in the pill.
 *
 * A tagger control is a wrapper containing:
 * - A preDefinedTagsList datalist used to present tags that already exist in
 *   the database in a "type-ahead" manner to the user; if the event is tagged
 *   with a pre-defined tag, it should not be presented as an option, so it
 *   needs to be removed from this list (and added back if the tag is removed)
 * - A tagsValue hidden input, which contains the list of tags applied to this
 *   event; it acts as the intermediary between the client- and server-code:
 *   when Rails renders the form it sets the value of this field with the
 *   taggings currently on this event, and the JS generates the pretty pills
 *   from it; then the JS modifies this value as the user adds and removes tags;
 *   when the form gets submitted, Rails steps in again to turn this list into
 *   taggings on the event
 * - An innerTextInput text input used to type the text of new tags; it is
 *   styled to remove all visible borders and outlines so that it looks like the
 *   user is typing in the parent tagger div, which is styled to look like a
 *   text input
 * - Zero or more pills representing one tag each
 */
export default class extends Controller {
  static targets = [
    "tagsValue",
    "preDefinedTagsList",
    "innerTextInput",
    "pill",
  ];
  static values = { preDefinedTags: String };

  // Initialize the control
  connect() {
    if (!this.tagsValue) return;

    /* Take the value from the Rails model (`IEvent#tags_text`) stored in a hidden field,
     * split it, and add a tag for each, and also remove each from the pre-defined options,
     * so that the tags don't show up in the list of tags to choose from when adding new ones.*/
    this.tagsValue.split(" ").forEach((tagText) => {
      const isPreDefined = this.allPreDefinedTags.includes(tagText);

      this.pillify(tagText, isPreDefined);
      if (isPreDefined) this.removeTagFromPreDefinedOptions(tagText);
    });
  }

  // Normalizes some text and creates a tag from it
  addTag(tagText) {
    // Normalize
    tagText = tagText.toLowerCase();
    if (tagText === "" || this.isTaggedWith(tagText)) return;

    const isPreDefined = this.allPreDefinedTags.includes(tagText);

    this.addTagToValue(tagText); // So that the tag is submitted with the form
    this.pillify(tagText, isPreDefined); // Create the pill
    this.removeTagFromPreDefinedOptions(tagText); // Remove from list of future tags
  }

  /* Adds the text of a tag that was previously removed from the pre-defined
   * tags list back in */
  addTagToPreDefinedOptions(tagText) {
    let opt = document.createElement("option");
    opt.append(tagText);
    this.preDefinedTagsListTarget.append(opt);
  }

  // Adds a tag to the list of tags that will be submitted with the form
  addTagToValue(tagText) {
    this.tagsValue = [...this.tagsValue.split(" "), tagText].join(" ");
  }

  /* Takes care of focusing the actual input in this control when focusing the
   * "fake" input (the div styled to look like an input) that contains it */
  innerFocus() {
    this.innerTextInputTarget.focus();
  }

  // Determines if a tag with the given tag text exists
  isTaggedWith(tagText) {
    return this.tagsValue.split(" ").includes(tagText);
  }

  /* Handles keyboard navigation both within the control and to leave it
   * - Left and right arrow keys navigate to the previous/next tag or text input
   *   within this control respectively
   * - Tab and Shift-Tab navigate to the next/previous control in the form,
   *   regardless of which internal tag/input is currently focused */
  keyboardNavigation(e) {
    const previousInnerControl = this.previousInnerControlTo(e.target);
    const nextInnerControl = this.nextInnerControlTo(e.target);

    switch (e.code) {
      case "ArrowLeft":
        if (previousInnerControl) previousInnerControl.focus();
        break;
      case "ArrowRight":
        if (nextInnerControl) nextInnerControl.focus();
        break;
      case "Tab":
        // Shift-Tab
        if (e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altGraphKey) {
          e.target
            .closest(".form-group")
            .previousElementSibling.querySelector("input")
            .focus();
          e.preventDefault();

          // Tab
        } else if (!e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altGraphKey) {
          e.target
            .closest(".form-group")
            .querySelector("input[type=text]")
            .focus();
          // Do not preventDefault, so that it tabs forward to the next field
        }
    }
  }

  /* Used to determine which control (if any) occurs after the given element
   * inside this control */
  nextInnerControlTo(element) {
    if (element.type === "text") return null; // The text input is always last

    // If the text input is not focused, then a pill's close button must be
    const parentPill = element.closest(".pill");

    if (parentPill.nextElementSibling.type === "text")
      return parentPill.nextElementSibling;

    // The next element is another pill, so return its close button
    return parentPill.nextElementSibling.querySelector("button.close");
  }

  // Creates the HTML structure of a pill, and appends it to the existing pills
  pillify(tagText, isPredefined = true) {
    const escapedText = escapeHTML(tagText);

    let pill = `
<span data-tagger-target="pill" class="pill badge badge-pill ${
      isPredefined ? "badge-primary" : "badge-warning"
    }">
  <span class="tag-text">${escapedText}</span>
  <button type="button" class="close" aria-label="Remove" data-action="tagger#removeTag">
    <span aria-hidden="true">&times;</span>
  </button>
</span>
`;

    this.innerTextInputTarget.insertAdjacentHTML("beforebegin", pill);
  }

  /* Handles taking the text in the "actual" text input inside this control, and
   * adds a tag for it, clearing out the input, ready for the next tag input;
   * This is normally done by the `specialKeys` event, but this event is
   * triggered by a `blur` or `change` on the "actual" inner text input */
  pillifyInput(e) {
    this.addTag(e.target.value);
    e.target.value = "";
  }

  /* Used to determine which control (if any) occurs before the given element
   * inside this control */
  previousInnerControlTo(element) {
    if (element.type === "text") {
      // `element` is the input text box and there are no pills
      if (!element.previousElementSibling) return null;
      // ... or there is a pill and we return its close button
      return element.previousElementSibling.querySelector("button.close");
    }

    // If the text input is not focused, then a pill's close button must be
    const parentPill = element.closest(".pill");

    // `element` is the close button of the first pill
    if (!parentPill.previousElementSibling) return null;

    // The previous element is another pill, so return its close button
    return parentPill.previousElementSibling.querySelector("button.close");
  }

  removeTag(e) {
    const pill = e.currentTarget.closest(".pill");
    const tagText = pill.querySelector(".tag-text").textContent;

    this.removeTagFromValue(tagText);
    pill.remove();
    if (this.preDefinedTagsValue.split(" ").includes(tagText))
      this.addTagToPreDefinedOptions(tagText);
  }

  /* Removes the <option> for a given tag from the datalist of options so that
   * it doesn't show up as an option to pick from when adding new tags */
  removeTagFromPreDefinedOptions(tagText) {
    this.preDefinedTagsListTarget.querySelectorAll("option").forEach((opt) => {
      if (opt.value === tagText) opt.remove();
    });
  }

  /* Removes a tag matching the given tag text from the list of tags that will
   * eventually be submitted with the form */
  removeTagFromValue(tagText) {
    this.tagsValue = this.tagsValue
      .split(" ")
      .filter((tag) => tag !== tagText)
      .join(" ");
  }

  /* Handles specific keypresses in the internal "actual" text input to create
   * and remove tags; also stubbornly ignores underscores as they are not
   * allowed in tags */
  specialKeys(e) {
    switch (e.code) {
      case "Space":
      case "Enter":
      case "Comma": // Create a tag on Space, Enter, or Comma
        e.currentTarget.value
          .split(/[, \n\t]+/)
          .forEach((tag) => this.addTag(tag));
        e.currentTarget.value = "";
        e.preventDefault();
        break;
      case "Backspace": // Remove the last tag on Backspace with empty input
        if (e.currentTarget.value === "") {
          const lastPill = this.pillTargets[this.pillTargets.length - 1];
          lastPill.querySelector(".close").click();
        }
        break;
    }

    // Ignore underscores
    if (e.key === "_") e.preventDefault();
  }

  /* An array of all pre-defined tags, regardless of whether they exist in the
   * value of this control; used to determine if an option should be created in
   * the preDefinedTagsList datalist */
  get allPreDefinedTags() {
    return Array.from(
      this.preDefinedTagsListTarget.querySelectorAll("option")
    ).map((opt) => opt.value);
  }

  /* Get the value of the hidden field that stores the actual value of the tags
   * for the event. This is the value we get from the Rails model by calling
   * `IEvent#tags_text`, and what will eventually be submitted as form data and
   * stored back in the model. */
  get tagsValue() {
    return this.tagsValueTarget.value;
  }

  /* Set the value of the hidden field that stores the actual value of the tags
   * for the event. */
  set tagsValue(value) {
    this.tagsValueTarget.value = value;
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["attachment", "hiddenFields", "control"];

  /* Creates a hidden input/field that is submitted with the form to to tell
   * Rails which attachments to delete */
  markForRemoval(attachmentId) {
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "i_event[purge_files][]");
    hiddenInput.setAttribute("value", attachmentId);

    this.hiddenFieldsTarget.append(hiddenInput);
  }

  // Handles the attachment's remove button click
  remove(event) {
    const attachment = event.currentTarget.closest(".attachment");
    const attachmentId = attachment.dataset.attachmentId;
    const control = this.controlTarget;
    const formGroup = attachment.closest(".form-group");
    const list = attachment.closest("ul");

    this.markForRemoval(attachmentId);
    attachment.remove();

    // Take the file input out of the list if the list contains no attachments
    if (!this.hasAttachmentTarget) {
      control.remove();
      list.remove();
      formGroup.append(control);
    }
  }
}
